<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

        <!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input v-model="searchForm.news_title" size="small" placeholder="新闻标题"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px" />
        <el-date-picker v-model="searchForm.release_time" size="small" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期"
          style="max-width: 300px; margin-right: 10px; margin-left: 10px">
        </el-date-picker>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
        <el-button type="primary" size="small" @click="onAdd">添加</el-button>

      </div>

    </el-row>
    <div class="dividerBar"></div>
    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="news_title" label="新闻标题" min-width="200"></el-table-column>

      <el-table-column prop="release_time" label="发布时间" min-width="120"></el-table-column>
      <el-table-column prop="app_send" label="是否发送通知" width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.app_send == 1">通知</div>
          <div v-if="scope.row.app_send != 1">不通知</div>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="type" label="来源" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type == '1'">校内新闻</span>
          <span v-if="scope.row.type == '2'">后台添加</span>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="flexStart">
            <div class="flexStart">
              <el-button type="text" size="small" @click="onView(scope.row)" style="margin: 0 !important">详情
              </el-button>
              <el-popconfirm title="确定删除吗？" @confirm="delectOne(scope.row)">
                <el-tag slot="reference" type="danger" size="mini" style="margin: 10px;">删除</el-tag>
              </el-popconfirm>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="新闻详情" :visible.sync="dialogVisible" width="800px">
      <div class="newsDetail" v-if="row" v-html="row.detail"></div>
    </el-dialog>
    <el-dialog title="新闻详情" :visible.sync="dialogVisible" width="800px">
      <el-form ref="form" :model="ruleForm" label-width="80px">
        <el-form-item label="新闻标题">
          <el-input :disabled="ruleForm.id ? true : false" v-model="ruleForm.news_title" size="small"></el-input>
        </el-form-item>
        <el-form-item label="详情">
          <UEditor ref="ue" @ready="editorReady" style="width: 100%"></UEditor>
        </el-form-item>
        <el-form-item label="微信通知">
          <el-radio-group :disabled="ruleForm.id ? true : false" v-model="ruleForm.app_send" size="small">
            <el-radio :label="1">通知</el-radio>
            <el-radio :label="0">不通知</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div v-if="!ruleForm.id" slot="footer">
        <el-button type="primary" @click="dialogVisible = false" plain size="small">关闭</el-button>
        <el-button type="primary" :loading="isLoading" @click="onSubmit" size="small">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
import UEditor from "../../notify/ueditor.vue";
export default {
  components: { tchtree, UEditor },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm:{},
      row: null,
      isLoading: false,
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    getlist() {
      this.$http
        .post("/api/sys_news_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          type: 1,
          news_title: this.searchForm.news_title,
          release_time: this.searchForm.release_time,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    findOne(e) {
      this.row = e;
      this.dialogVisible = true;
    },
    onView(e) {
      let _this = this
      this.ruleForm = e;
      this.dialogVisible = true;
      setTimeout(() => {
        _this.editor.setContent(e.detail);
      }, 300);
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.ruleForm.detail);
      editorInstance.addListener("contentChange", () => {
        this.ruleForm.detail = editorInstance.getContent();
      });
      this.editor = editorInstance;
    },
    onSubmit() {
      let _this = this
      this.isLoading = true
      setTimeout(() => {
        _this.isLoading = false
      }, 1000);
      this.$http
        .post("/api/sys_news_save", {
          id: this.ruleForm.id,
          detail: this.editor.getContent(),
          news_title: this.ruleForm.news_title,
          release_time: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
          type: 1,
          app_send: this.ruleForm.app_send,
        })
        .then((res) => {
          this.dialogVisible = false;
          setTimeout(() => {
            this.isLoading = false
            this.getlist();
          }, 300);
        });

    },
    onAdd() {
      let _this = this
      this.ruleForm = {
        detail: "",
        news_title: "",
        sort: 1,
        app_send: 0
      };
      this.dialogVisible = true;
      setTimeout(() => {
        _this.editor.setContent('');
      }, 300);
    },
    delectOne(e) {
      this.$http
        .post("/api/sys_news_delete", {
          id: e.id,
        })
        .then((res) => {
          this.getlist();
        });
    },
  },
};
</script>

<style scoped>
.newsDetail img {
  width: 100% !important;
  height: auto !important;
}
</style>
